.App {
  text-align: center;
  direction: rtl;
}

@media only screen and (max-width: 600px) {
  .App {
    margin-top: 50%;
  }
}

.mb10{
  margin-bottom: 10px;
  font-size: 4vh;
}


h1{
  font-size: 6vh;
}

i{
  margin:50px 25px;
  font-size:8vh;
  cursor: pointer;
}


.leftWords{
  position: absolute;
  padding: 10px;
  left: 0;
  top: 0;
  direction: ltr;
}

.trophy > i{
  cursor: default;
  font-size: 2vh;
  margin: 0;
  color: black;
  padding-left: 5px;
}
.trophy{
  font-weight: bold;
  color: #63921b;
  position: absolute;
  padding: 10px;
  right: 0;
  top: 0;
  direction: ltr;
}


